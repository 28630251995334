module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Remote Guys Night","short_name":"RGN","start_url":"/","background_color":"#000000","theme_color":"#8940fa","display":"minimal-ui","icon":"src/images/icon.svg"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["UA-59245528-20"],"pluginConfig":{}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
